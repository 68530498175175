// import { toStr } from '@enonic/js-utils/value/toStr';
import { Part } from '@enonic/react-components';
import React from 'react';
import Header, { type HeaderProps } from '../components/software-show/Header/Header';
import Metadata, { type MetadataProps } from '../components/software-show/Metadata/Metadata';
import Changelog, { type ChangelogProps } from '../components/software-show/Changelog/Changelog';
import Enonicarousel, { type EnonicarouselProps } from '../components/Enonicarousel';


interface SoftwareShowProps {
	selectedVersion: HeaderProps['selectedVersion']
	header: HeaderProps['header']
	metadata: MetadataProps['metadata']
	carousel: EnonicarouselProps
	details?: string
	changelog: ChangelogProps['changelog']
	downloadInstructions: HeaderProps['downloadInstructions']
}


export function SoftwareShow({
	selectedVersion,
	header,
	metadata,
	carousel,
	details,
	changelog,
	downloadInstructions,
	...extraPartProps
}: SoftwareShowProps) {
	// console.debug('SoftwareShow', toStr({ selectedVersion, header, metadata, carousel, details, changelog, downloadInstructions }));
	return (
		<Part
			{...extraPartProps}
			as='article'
			className="software-show"
		>
			<Header {...{ header, selectedVersion, downloadInstructions }} />
			<Metadata metadata={metadata} />
			<div className="software-show__body">
				<Enonicarousel {...carousel} />
				<section className="software-show__description">
					{ details && <div dangerouslySetInnerHTML={{ __html: details }} /> }
				</section>
				<Changelog changelog={changelog} />
			</div>
		</Part>
	);
}
