// TODO 7.15.0-B1 release of @enonic-types/core
// import type {Region} from '@enonic-types/core';
import type {ComponentRegistry} from '@enonic/react-components';
import type {FooterProps} from '../components/Footer';
import type {HeaderProps} from '../components/Header/Header';

// import { toStr } from '@enonic/js-utils/value/toStr';
import {Page} from '@enonic/react-components';
import * as React from 'react';
import Header from '../components/Header/Header';
import { Footer } from '../components/Footer';


export interface DefaultPageProps {
	componentRegistry: ComponentRegistry;
	footerProps: FooterProps;
	headerProps: HeaderProps;
	// regions: Record<string, Region>;
	regions: Record<string, unknown>;
}


export const DefaultPage = (props: DefaultPageProps) => {
	const {
		componentRegistry,
		footerProps,
		headerProps,
		regions,
		...extraPageProps
	} = props;
	// console.info('DefaultPage headerProps:', toStr(headerProps));
	// console.info('DefaultPage regions:', toStr(regions));
	// console.info('DefaultPage footerProps:', toStr(footerProps));
	return (
		<>
			<div>
				<Header {...headerProps}/>
			</div>
			<Page
				{...extraPageProps}
				as="main"
				componentRegistry={componentRegistry}
				// @ts-expect-error regions not compatible
				regions={regions}
			/>
			<div>
				<Footer {...footerProps}/>
			</div>
		</>
	);
}
