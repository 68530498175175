// TODO 7.15.0-B1 release of @enonic-types/core
// import type {Region} from '@enonic-types/core';
import type {ComponentRegistry} from '@enonic/react-components';

import {Layout} from '@enonic/react-components';
import * as React from 'react';

export interface LayoutDefaultProps {
	componentRegistry: ComponentRegistry;
	// regions: Record<string, Region>;
	regions: Record<string, unknown>;
}

export const LayoutDefault = (layoutProps: LayoutDefaultProps) => (
	// @ts-expect-error regions not compatible
	<Layout
		className="container"
		{...layoutProps}
	/>
);
