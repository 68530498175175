import { Part } from '@enonic/react-components';
import React from 'react';
import Search, { type SearchProps } from '../components/Search';
import ProductListing, { type Product } from '../components/ProductListing';


interface SearchResultProps {
	aggregations: {
		docCount: number
		hits: Product[]
		name: string
	}[]
	search: SearchProps
}


export function SearchResult({
	aggregations,
	search,
	...extraPartProps
}: SearchResultProps) {
	return (
		<Part
			{...extraPartProps}
			className='search-result'
		>
			<header className="search-result__header">
				<h1 className="search-result__heading">Search</h1>
				<Search {...search} />
			</header>
			{
				aggregations.map((aggregation, i) => (
					<section className="search-result__aggregation-item" key={`search-result-aggregation-item-${i}`}>
						<header className="search-result__aggregation-header">
							<h2 className="search-result__aggregation-heading">
								<span className="search-result__aggregation-name">
									{aggregation.name}
								</span>
								<span className="search-result__aggregation-count">
									<span>
										{' '}
										{aggregation.docCount}
									</span>
									<span className="visually-hidden">hits</span>
								</span>
							</h2>
						</header>

						<ProductListing products={aggregation.hits} />

					</section>
				))
			}
		</Part>
	);
}
