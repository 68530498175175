import * as React from 'react';
import { useId } from 'react';

export interface FooterProps {
	currentPath: string
	footerText: string // html
	menu: {
		menuItems: {
			path: string
			title: string
			url: string
		}[]
	}
	socialLinks: {
		name: string
		url: string
	}[]
}

export function Footer({
	currentPath,
	footerText,
	menu: {
		menuItems
	},
	socialLinks
}: FooterProps) {
	const menuItemKeyPrefix = useId();
	const socialLinkItemKeyPrefix = useId();
	return (
		<footer className="page-default__footer">

			<nav className="page-default__footer-nav">
				<ul className="page-default__footer-nav-list">
					{ menuItems.map((menuItem) => (
						<li className="page-default__footer-nav-item" key={`${menuItemKeyPrefix}-${menuItem.url /* assuming the same url isn't allowed twice */}`}>
							<a
								className={`page-default__footer-nav-link${menuItem.path === currentPath ? ' page-default__footer-nav-link--active' : ''}`}
								href={menuItem.url}
							>
								{menuItem.title}
							</a>
						</li>
					))}
				</ul>
			</nav>

			<section className="page-default__footer-social">
				<h3 className="page-default__footer-social-heading">Follow us</h3>
				<ul className="page-default__footer-social-list">
					{ socialLinks.map((socialLink) => (
						<li className="page-default__footer-social-item" key={`${socialLinkItemKeyPrefix}-${socialLink.url /* assuming the same url isn't allowed twice */}`}>
							<a
								className={`page-default__footer-social-link page-default__footer-social-link--${socialLink.name}`}
								href={socialLink.url}
							>
								{socialLink.name}
							</a>
						</li>
					))}
				</ul>
			</section>

			<div className="page-default__footer-copyright">
				<div className="page-default__footer-copyright-text" dangerouslySetInnerHTML={{ __html: footerText }} />
			</div>
		</footer>
	);
}
