import type {AppProps} from './AppProps.d';

// import { toStr } from '@enonic/js-utils/value/toStr';
import * as React from 'react';

import {BaseComponent} from '@enonic/react-components';
import {componentRegistry} from '../componentRegistry';


const App: React.FC<AppProps> = (props) => {
	// console.info('App props sent to BaseComponent:', toStr(props));
	return (
		<BaseComponent componentRegistry={componentRegistry} {...props}/>
	);
}

App.displayName = 'App';

export default App;
