import { Part } from '@enonic/react-components';
import React from 'react';
import Enonicarousel, { type EnonicarouselProps } from '../components/Enonicarousel';


export interface PromotionBannerProps {
	carousel: EnonicarouselProps
}


export function PromotionBanner(props: PromotionBannerProps) {
	const {
		carousel,
		...extraPartProps
	} = props;

	return (
		<Part
			{...extraPartProps}
			className='promotion-banner'
		>
			<Enonicarousel {...carousel} />
		</Part>
	);
}
