// regenerator-runtime is a "polyfill" for async await.
// Graal-JS and all modern browsers support async await.
// https://caniuse.com/?search=async%20await
// Internet Explorer and Nashorn don't, but React4xp don't support those anymore :)
//
// It might still be possible to support IE, if one polyfills only in the browser
// <script nomodule src="https://cdn.jsdelivr.net/npm/regenerator-runtime@0.13.5/runtime.js"></script>
// import regeneratorRuntime from "regenerator-runtime";


import { Part } from '@enonic/react-components';
import * as React from 'react';
import { useCallback, useState, useEffect } from 'react';
import ProductListing, { type Product } from '../components/ProductListing';


type SoftwareType = 'all'|'application'|'starter'|'library'

interface SoftwareListProps {
	componentPath: string;
	numOfItemsToIncrease: number;
	numOfItemsToShow: number;
	softwares: {
		total: number
		hits: Product[]
	};
	softwareListServiceUrl: string;
	softwareType: SoftwareType;
	vendor: {
		id?: string|null;
		name?: string|null;
	};
}


export function SoftwareList({
	componentPath,
	numOfItemsToIncrease,
	numOfItemsToShow,
	softwares: {
		hits,
		total,
	},
	softwareListServiceUrl,
	softwareType,
	vendor: {
		id: vendorID, // can be null
		name: vendorName, // can be null
	},
	...extraPartProps
}: SoftwareListProps) {
	const [startAt, updateStartAt] = useState(numOfItemsToShow);
	const [softwares, updateSoftwares] = useState(hits);
	const [isLoadMoreClickable, updateIsLoadMoreClickable] = useState(total > hits.length);

	// Every time the state "softwares" is updated, check if it is the right time to
	// hide "load more" button. If so, hide it.
	useEffect(() => {
		if (softwares.length === total) {
			updateIsLoadMoreClickable(false);
		}
	},[
		softwares,
		total
	]);

	// Execute a post request to the softwareListServiceUrl to get more softwares data
	// startAt is passed in rather than beeing a dep, so react doesn't have to update the callback.
	const loadMore = useCallback(({startAt}: {startAt: number}) => {
		fetch(softwareListServiceUrl, {
			method: 'POST',
			body: JSON.stringify({
				componentPath,
				count: numOfItemsToIncrease,
				numOfItemsToIncrease,
				numOfItemsToShow,
				softwareType,
				start: startAt,
				vendorID,
			}),
			// headers: {
			// 	accept: 'application/json', // Needed to get JSON response
			// }
		})
			.then((response) => {
				if (response.status !== 200) {
					throw response.statusText;
				}
				return response.json();
			})
			.then((data) => {
				updateSoftwares(prev => [
					...prev,
					...data.softwares.hits,
				]);
				// Using prevState on numbers doesn't work even though the doc says it should.
				// https://react.dev/reference/react/useState#updating-state-based-on-the-previous-state
				const newStartAt = startAt + numOfItemsToIncrease;
				updateStartAt(newStartAt);
			})
			.catch((error) => {
				console.error("Error:", error);
			});
	}, [
		componentPath,
		numOfItemsToIncrease,
		numOfItemsToShow,
		softwareListServiceUrl,
		softwareType,
		vendorID
	]);

	return (
		<Part
			{...extraPartProps}
			className='software-list'
		>
			{
				vendorName && (
					<div className="vendor-show">
						<header className="vendor-show__header">
							<h1 className="vendor-show__heading">{vendorName}</h1>
						</header>
					</div>
				)
			}

			<ProductListing products={softwares} />
			{
				isLoadMoreClickable && (
					<div className="product-listing">
						<button
							type="button"
							className="btn btn--pagination"
							onClick={() => loadMore({startAt})}
						>LOAD MORE</button>
					</div>
				)
			}
		</Part>
	);
}
