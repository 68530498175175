import { Part } from '@enonic/react-components';
import * as React from 'react';
import ProductListing, {Product} from '../components/ProductListing';


export interface FeaturedListingsProps {
	heading?: string
	listings?: Product[]
	viewAllPage: string // href
}


export const FeaturedListings = ({
	heading,
	listings,
	viewAllPage,
	...extraPartProps
}: FeaturedListingsProps) => (
	<Part
		{...extraPartProps}
		as="section"
		className="featured-listings"
	>
		{ heading && viewAllPage && (
			<header className="featured-listings__header">
				<a className="featured-listings__view-all-link" href={viewAllPage}>
					<h2 className="featured-listings__heading">{ heading }</h2>
					<span className="featured-listings__view-all">{`View all ${heading}`}</span>
				</a>
			</header>
		)}
		{ listings && <ProductListing products={listings} /> }
	</Part>
);
FeaturedListings.displayName = 'FeaturedListings';
