import { ComponentRegistry } from '@enonic/react-components';

import { LayoutDefault } from './layouts/LayoutDefault';
import { DefaultPage } from './pages/DefaultPage';
// import { ComponentUrl } from './parts/ComponentUrl';
import { FeaturedListings } from './parts/FeaturedListings';
import { FpIntro } from './parts/FpIntro';
import { LatestItems } from './parts/LatestItems';
import { PromotionBanner } from './parts/PromotionBanner';
import { SearchResult } from './parts/SearchResult';
import { SoftwareList } from './parts/SoftwareList';
import { SoftwareShow } from './parts/SoftwareShow';

export const componentRegistry = new ComponentRegistry();

componentRegistry.addPage('com.enonic.app.market:default', { View: DefaultPage });

componentRegistry.addLayout('com.enonic.app.market:layout-default', { View: LayoutDefault});

componentRegistry.addPart('com.enonic.app.market:featured-listings', { View: FeaturedListings });
componentRegistry.addPart('com.enonic.app.market:fp-intro', { View: FpIntro });
componentRegistry.addPart('com.enonic.app.market:latest-items', { View: LatestItems });
componentRegistry.addPart('com.enonic.app.market:promotion-banner', { View: PromotionBanner });
componentRegistry.addPart('com.enonic.app.market:search-result', { View: SearchResult });
componentRegistry.addPart('com.enonic.app.market:software-list', { View: SoftwareList });
componentRegistry.addPart('com.enonic.app.market:software-show', { View: SoftwareShow });
