import { Part } from '@enonic/react-components';
import React from 'react';


export interface LatestItemsProps {
	config: {
		show: boolean
		unavailableMessage: string
	}
	data: {
		heading?: string
		items: {
			icon: string
			name: string
			readableTimeDifference: string
			shortDescription: string
			url: string
		}[]
		viewAllPage?: string // href
	}
}


export const LatestItems = ({
	config: {
		show,
		unavailableMessage
	},
	data: {
		heading,
		items,
		viewAllPage
	},
	...extraPartProps
}: LatestItemsProps) => {
	return (
		<Part
			{...extraPartProps}
			className="latest-items"
		>
			<div className="latest-items__heading">
				{ heading && <h2>{ heading }</h2> }
				{ viewAllPage && <a href={viewAllPage}>{`View all ${heading}`}</a> }
			</div>
			<div className="latest-items__wrapper">
				{
					show ? items.map(({
						icon,
						name,
						readableTimeDifference,
						shortDescription,
						url
					}, i) => (
						<a className="latest-items__card" href={url} key={`latest-items-card-${i}`}>
							<div className="latest-items__card-icon">
								<img src={icon} alt={name} />
							</div>

							<h3 className="latest-items__card-heading">{ name }</h3>

							<div className="latest-items__card-description">
								<p>{ shortDescription }</p>
								<p>{ readableTimeDifference }</p>
							</div>
						</a>
					)) : <p>{ unavailableMessage }</p>
				}
			</div>
		</Part>
	);
}
